<template>
  <div
    class="flex flex-col items-center justify-center  overflow-hidden"
    :style="layoutStyle"
    :class="layoutClass"
  >
    <img :src="url" style="min-width: 100%" class="object-contain" />
  </div>
</template>

<script>
import layoutMixin from "@/mixins/layoutMixin";

export default {
  name: "SocialAvatar",
  mixins: [layoutMixin],
  props: {
    url: String,
    hasImage: Boolean,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
};
</script>
